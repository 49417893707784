const overflowEllipsis = {
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const baseButton = {
  fontFamily: "Inter, sans-serif",
  cursor: "pointer",
  "&:disabled": { opacity: 0.75, cursor: "initial" },
};

export const themevals = {
  colors: {
    primary: "#2D3648",
    secondary: "#717D96",
    base_100: "#F7F9FC",
    base_200: "#EDF0F7",
    base_300:"#E2E7F0",
    base_400: "#CBD2E0",
    base_500: "#A0ABC0",
    base_600: "#717D96",
    base_700: "#4A5468",
    base_800: "#2D3648",
    success: "#68B15C",
    warning: "#b28d1d",
    danger: "#EA1A4C",
    light_color: "#CBD2E0",
    text: "#2D3648",
    green: "#1eb768",
    red: "#EA1A4C",
    ltgrey: "#5d5e62",
    mdgrey: "#717D96",
    dkgrey: "#A9B3BF",
    white: "#fff",
    body:"#4A5468",
    blue: "#34AFF7"
  },
  fonts: {
    body: "Inter, sans-serif",
    bold: "Work Sans Semi, sans-serif",
    medium: "Work Sans Medium, sans-serif",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    light: 300,
    body: 400,
    medium: 500,
    semi: 600,
    bold: 700,
    bolder: 900,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  borders: [0, 6, 12, 24],
  styles: {
    root: {
      fontFamily: "body",
      a: {
        color: "primary",
        fontFamily: "body",
        "&:hover": {
          color: "secondary",
        },
      },
      h1: {
        fontSize: "26px",
        mt: 0,
        mb: 0,
      },
    },
    hr: {
      color: "#BECAD8",
    },
  },
  text: {
    muted: {
      color: "#a9b3bf",
      fontWeight: 500,
      ...overflowEllipsis,
    },
    primary: {
      color: "#254778",
      fontWeight: 500,
      ...overflowEllipsis,
    },
    secondary: {
      color: "#4893F7",
      fontWeight: 500,
      ...overflowEllipsis,
    },
  },
  forms: {
    input: {
      display: "inline-block",
      borderRadius: 0,
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
      padding: "6px 0",
      color: "#254778",
      borderColor: "#BECAD8",
    },
    switch: {
      width: 35,
      bg: "#E9E9EA",
      "input:checked ~ &": {
        bg: "#4893F7",
        div: { transform: "translateX(70%)" },
      },
    },
    checkbox: {
      width: 20,
      height: 20,
    },
    select: {
      fontFamily: "Work Sans",
      color: "primary",
      borderRadius: 0,
      borderColor: "#A9B3BF",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      padding: "8px 0 4px 0",
      fontSize: 14,
      fontWeight: 500,
      "& + svg": {
        fill: "#A9B3BF",
      },
    },
  },
  buttons: {
    icon: {
      ...baseButton,
      width: 30,
      height: 30,
      minWidth: 30,
      color: "#254778",
      border: "2px solid",
      borderRadius: "50%",
      padding: "initial",
      cursor: "pointer",
    },
    iconOutline: {
      ...baseButton,
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      height: 30,
      minWidth: 30,
      fontWeight: 600,
      fontSize: 14,
      backgroundColor: "transparent",
      color: "#254778",
      border: "1px solid #A9B3BF",
      borderRadius: "6px",
      padding: "10px 9px",
      cursor: "pointer",
      "&:hover": {
        "&:not([disabled])": {
          borderColor: "#4893F7",
          backgroundColor: "#4893F7",
          color: "white",
          svg: { fill: "white" },
        },
      },
    },
    link: {
      ...baseButton,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 30,
      height: 30,
      minWidth: 30,
      backgroundColor: "transparent",
      color: "#A9B3BF",
      border: "none",
      borderRadius: "50%",
      padding: "initial",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#254778",
        color: "white",
      },
    },
    secondary: {
      ...baseButton,      
      svg: {
        width: 24,
        height: 24,
        fill: "white",
      },
    },
  },
  badges: {
    primary: {
      display: "inline-block",
      color: "#fff",
      backgroundColor: "#2D3648",
      borderRadius: "50rem",
      fontWeight: "500",
      paddingRight : "1.75rem",
      paddingLeft : "1.75rem",
      paddingTop : ".5rem",
      paddingBottom : ".5rem", 
    },
    light: {
      display: "inline-block",
      color: "#fff",
      backgroundColor: "#a9b3bf",
      borderRadius: "50rem",
      fontWeight: "500",
      paddingRight : "1.75rem",
      paddingLeft : "1.75rem",
      paddingTop : ".5rem",
      paddingBottom : ".5rem", 
    },
    outlineSecondary: {
      display: "inline-block",
      color: "secondary",
      backgroundColor: "transparent",
      border: "1px solid #4893F7",
      borderRadius: "50rem",
      fontWeight: "500",
      paddingRight : "1.75rem",
      paddingLeft : "1.75rem",
      paddingTop : ".5rem",
      paddingBottom : ".5rem", 
    },
    success: {
      display: "inline-block",
      color: "#68B15C",
      backgroundColor: "#68B15C33",
      borderRadius: "50rem",
      fontWeight: "500",
      paddingRight : "1.75rem",
      paddingLeft : "1.75rem",
      paddingTop : ".5rem",
      paddingBottom : ".5rem",            
    },
    danger: {
      display: "inline-block",
      color: "#EA1A4C",
      backgroundColor: "#EA1A4C33",
      borderRadius: "50rem",
      fontWeight: "500",
      paddingRight : "1.75rem",
      paddingLeft : "1.75rem",
      paddingTop : ".5rem",
      paddingBottom : ".5rem",            
    },
    info: {
      display: "inline-block",
      color: "#34AFF7",
      backgroundColor: "#34AFF733",
      borderRadius: "50rem",
      fontWeight: "500",
      paddingRight : "1.75rem",
      paddingLeft : "1.75rem",
      paddingTop : ".5rem",
      paddingBottom : ".5rem",         
    },
    small: {
      fontSize: ".75rem",
      paddingRight : ".35rem",
      paddingLeft : ".35rem",
      paddingTop : ".1rem",
      paddingBottom : ".1rem",       
    },
  },
};
