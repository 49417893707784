import React, { useState, useEffect } from "react";

export default function ArrowIcon(props?: any) {
  const [dir, setDir] = useState("rotate(180deg)");

  const svgStyles: any = {
    width: props.width ? props.width : null,
    height: props.height ? props.height : null,
    fill: props.fill ? props.fill : null,
    margin: props.margin ? props.margin : null,
    transform: dir

  };

  const filteredStyles = Object.keys(svgStyles)
  .filter(key => svgStyles[key] !== null && svgStyles[key] !== undefined)
  .reduce((acc: any, key) => {
    acc[key] = svgStyles[key];
    return acc;
  }, {});


  useEffect(() => {
    if(props.direction === "up"){
      setDir("rotate(0deg)")
    }
    if(props.direction === "down"){
      setDir("rotate(180deg)")
    }
  }, [props.direction]);

  return (
    <svg
      viewBox="0 0 466.205 466.205"
      fill="none" 
      style={{...filteredStyles, fill : "none"}}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M416.193,170.894c-0.052-0.053-0.105-0.105-0.158-0.158L253.049,7.536c-1.295-1.288-2.726-2.432-4.267-3.413l-4.693-2.56
          l-3.2-1.067h-2.347c-3.596-0.661-7.283-0.661-10.88,0h-2.133h-2.56l-3.627,1.92c-2.021,1.098-3.887,2.461-5.547,4.053
          L50.169,170.736c-10.957,10.87-11.028,28.565-0.158,39.522c0.052,0.053,0.105,0.105,0.158,0.158
          c11.045,10.538,28.421,10.538,39.467,0l97.493-97.28c4.206-4.124,10.96-4.058,15.084,0.149c1.919,1.957,3.011,4.577,3.049,7.318
          v317.653c-0.001,15.435,12.51,27.948,27.945,27.949c15.435,0.001,27.947-12.51,27.949-27.945c0-0.001,0-0.003,0-0.004V120.603
          c0.083-5.89,4.925-10.599,10.815-10.516c2.74,0.039,5.361,1.13,7.318,3.049l97.067,97.28c11.073,10.674,28.607,10.674,39.68,0
          C426.993,199.546,427.064,181.851,416.193,170.894z"
          fill={filteredStyles.fill || "#3B4559"}
        />
      </g>
    </svg>
  );
}