import styled from "styled-components";

export const LoginMat = styled.div`
    width:500px;
    min-height:515px;
    padding:40px;
    margin-left:auto;
    margin-right:auto;
    background:white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius:6px;
    display:flex;
    justify-content:center;
`